import helpers from '~/helpers'
import type { ProductData } from '~/types/products'

declare global {
  // noinspection JSUnusedGlobalSymbols
  interface Window {
    mindbox: (
      type: string,
      payload: {
        data: OperationData
        onError: (e: object) => void
        onSuccess: () => void
        operation: MindboxOperation
      }
    ) => void
  }
}

type MindboxOperation = 'Website.AuthorizeCustomer'
  | 'Website.SetSearch'
  | 'Website.ViewCategory'
  | 'Website.ViewProduct'

type ProductListItem = {
  count: number,
  pricePerItem: number,
  product: {
    ids: {
      itab: string
    }
  }
}

type AuthorizeCustomerData = {
  customer: {
    email?: string
    ids: {
      websiteID: string
    }
    mobilePhone?: string
  }
}

type SetSearchData = {
  productList: ProductListItem[]
}

type ViewCategoryData = {
  viewProductCategory: {
    productCategory: {
      ids: {
        itab: string
      }
    }
  }
}

type ViewProductData = {
  viewProduct: {
    product: {
      ids: {
        itab: string
      }
    }
  }
}

type OperationData = Record<string, never> | AuthorizeCustomerData | SetSearchData | ViewCategoryData | ViewProductData

/**
 * Class MindboxApiService
 *
 * @see https://developers.mindbox.ru/docs/трекер
 */
export default class MindboxApiService {
  public static authorizeCustomer (userId: string, email: string | null, phoneNumber: string | null): void {
    const data: AuthorizeCustomerData = {
      customer: {
        ids: {
          websiteID: userId
        }
      }
    }

    if (email) {
      data.customer.email = email
    }

    if (phoneNumber) {
      data.customer.mobilePhone = phoneNumber.substring(1)
    }

    this.execute('Website.AuthorizeCustomer', data)
  }

  public static setSearch (productsData: ProductData[]): void {
    const data: SetSearchData = {
      productList: productsData.map(
        (x: ProductData): ProductListItem => ({
          count: 1,
          pricePerItem: helpers.getProductPrice(x)!,
          product: {
            ids: {
              itab: x.id
            }
          }
        })
      )
    }

    this.execute('Website.SetSearch', data)
  }

  public static viewCategory (categoryId: string): void {
    const data: ViewCategoryData = {
      viewProductCategory: {
        productCategory: {
          ids: {
            itab: categoryId
          }
        }
      }
    }

    this.execute('Website.ViewCategory', data)
  }

  public static viewProduct (productId: string): void {
    const data: ViewProductData = {
      viewProduct: {
        product: {
          ids: {
            itab: productId
          }
        }
      }
    }

    this.execute('Website.ViewProduct', data)
  }

  private static execute (mindboxOperation: MindboxOperation, data: OperationData = {} as Record<string, never>): void {
    if (!Object.hasOwn(window, 'mindbox')) {
      return
    }

    try {
      // noinspection JSUnusedGlobalSymbols
      window.mindbox('async', {
        data,
        onError: function (e: object): void {
          console.error(e)
        },
        onSuccess: function (): void {},
        operation: mindboxOperation
      })
    } catch (e) {
      console.error(e)
    }
  }
}
